import React, { Component, Fragment } from 'react'
import { X } from 'react-feather'
import './AnnualMeetingPopup.css'

class AnnualMeetingPopup extends Component {
  constructor(props) {
    super(props)
    this.state = { showPopup: false }
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    })
  }

  render() {
    const {
		title,
		date,
		event_name,
		event_body,
		children
	} = this.props
    return (
      <Fragment>
        <div className="AnnualMeetingCard" onClick={this.togglePopup.bind(this)}>
			<div className="AnnualMeetingCard--Content">
			  {title && <h2 className="AnnualMeetingCard--Title">{title}</h2>}
    		  <div className="AnnualMeetingCard--Date">
				{date && <div>{date}</div>}
    		  </div>
			  <span>{event_name}</span>
    		  {event_body && <h3 className="AnnualMeetingCard--Body">{event_body}</h3>}
    		</div>
        </div>

        {this.state.showPopup ? (
          <div className="AnnualMeetingPopup-Overlay">
            <div
              className="AnnualMeetingPopup-Background"
              onClick={this.togglePopup.bind(this)}
            ></div>
            <div className="AnnualMeetingPopup-Inner">
              <X className="AnnualMeetingPopup-Close" onClick={this.togglePopup.bind(this)} />
              {children}
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}
export default AnnualMeetingPopup
