import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import HistorySection from '../components/HistorySection'
import AnnualMeetingSearch from '../components/AnnualMeetingSearch'
import Layout from '../components/Layout'
import './HistoryPage.css'

export const HistoryPageTemplate = ({
	title,
	subtitle,
	featuredImage,
	posts  = [],
	enableSearch = true
}) => (
	<Location>
		{({location}) => {
			let filteredAnnualMeetings =  posts

			let queryObj = location.search.replace('?', '')
			queryObj = qs.parse(queryObj)

			if(enableSearch && queryObj.s){
				const searchTerm = queryObj.s.toLowerCase()
				filteredAnnualMeetings = filteredAnnualMeetings.filter(post => {
					const target = `
						${post.frontmatter.title.toLowerCase()}
						${post.frontmatter.date.toLowerCase()}
						${post.frontmatter.event_name.toLowerCase()}
					`
					return target.includes(searchTerm)
				})
			}

			return (
				<main className="History">
 		 		  <PageHeader
 		 		    large
 		 		    title={title}
					subtitle={subtitle}
 		 		    backgroundImage={featuredImage}
 		 		  />

				<section className="section thin">
            	  <div className="container">
					<div className="SearchAnnualMeetings">
						{enableSearch && <AnnualMeetingSearch />}
					</div>
            	  </div>
            	</section>

 		 		  {!!posts.length && (
 		 		    <section className="section">
 		 		      <div className="container">
 		 		        <HistorySection posts={filteredAnnualMeetings} />
 		 		      </div>
 		 		    </section>
 		 		  )}
 		 		</main>
			)
		}}
	</Location>
)

const HistoryPage = ({ data: { page, posts} }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HistoryPageTemplate
		{...page}
		{...page.frontmatter}
		posts={posts.edges.map(post => ({
			...post.node,
        	...post.node.frontmatter,
        	...post.node.fields
		}))}
	/>
  </Layout>
)

export default HistoryPage

export const pageQuery = graphql`
  query HistoryPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
		subtitle
        featuredImage
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "history" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
			event_name
			event_body
			program
			program_file
          }
        }
      }
    }
  }
`
