import React from 'react'
import AnnualMeetingPopup from  '../components/AnnualMeetingPopup'
import Content from '../components/Content'
import './HistorySection.css'

class HistorySection extends React.Component{
  static defaultProps = {
    posts: [],
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { posts, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visiblePosts = posts.slice(0, limit || posts.length)

    return (
      <div className="AnnualMeetingSection">
        {!!visiblePosts.length && (
          <div className="AnnualMeetingSection--Grid">
            {visiblePosts.map((post, index) => (
				<AnnualMeetingPopup {...post} key={index}>
					{post.title && <h2 className="AnnualMeetingCard--Title">{post.title}</h2>}
					<div className="AnnualMeetingCard--Popup--Date">
			  		  {post.date && <div>{post.date}</div>}
    		  		</div>
					<div className="AnnualMeetingCard--Popup--Event">
						{post.event_body ? 
							<span>{post.event_name}</span> :
							<h3 className="AnnualMeetingCard--Body">{post.event_name}</h3>
						}
						{post.event_body && <h3 className="AnnualMeetingCard--Body">{post.event_body}</h3>}
					</div>
					<Content source={post.program} className="AnnualMeetingCard--Program"/>
					{post.program_file && 
					<a href={`${post.program_file}/-/inline/yes/`}>
						<div className="Button Button--AnnualMeeting--Program">
							プログラム
          				</div>
					</a>}
				</AnnualMeetingPopup >
            ))}
          </div>
        )}
        {showLoadMore && visiblePosts.length < posts.length && (
          <div className="taCenter">
            <button className="button" onClick={this.increaseLimit}>
              {loadMoreTitle}
            </button>
          </div>
        )}
      </div>
    )
  }
}
export default HistorySection
